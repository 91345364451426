
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
export default defineComponent({
  components: {
    FlatButton,
  },
  methods: {
    onClickDashboard(): void {
      this.$router.push("/dashboard");
    },
  },
  beforeRouteLeave(to, from, next) {
    // Fix Infinite redirect in navigation guard
    if (to.name !== "Dashboard" && to.name !== "Login") {
      next({ name: "Dashboard" });
    }
    next();
  },
});
