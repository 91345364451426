import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b5eaa21"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-complete" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "containter-header" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "container-body" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "payment-complete--text mt-2" }
const _hoisted_9 = { class: "message" }
const _hoisted_10 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flat_button = _resolveComponent("flat-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("payment.paymentCompleteTaglineText")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("payment.paymentCompleteTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "payment-complete--image",
          src: require('images/payment-complete.svg'),
          alt: "payment-complete"
        }, null, 8, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("payment.paymentCompleteMessage")), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("payment.paymentCompleteMessage1")), 1)
        ])
      ]),
      _createVNode(_component_flat_button, {
        text: _ctx.$t('payment.showDashboard'),
        class: "show-dashboard--btn",
        onOnClick: _ctx.onClickDashboard
      }, null, 8, ["text", "onOnClick"])
    ])
  ]))
}